function getCustomizationVariables(customizationId) {

  let result = {
    brandName: '', //this is the 'BRAND' name
    nokuChainVisibility: false, //Shows Nokuchain in the list of avaible currencies
    tokenraiseVisibility: false, //Shows references to Tokenraise Platform
    tokenraiseUrl: '',
    customtokenVisibility: false, //Shows references to Custom Token Platform
    customtokenUrl: '',
    walletVisibility: false, //Shows reference to Wallet Platform
    walletUrl: '',
    pskVisibility: false,
    pskUrl: `https://stake.noku.io`,
    showLoginImage: true,
    telegramLogin: true,
    isKycEnabled: true,
    hasUsername: true,
    has2FA: true,
    hasDefaultEthereumAddress: true,
    hasSettings: true,
    accountsDescription: {
      en:
        `A Noku Account allows you to get access to the 
    Noku 
    Platform, an innovative tool made of interfaces, services
    and products, designed to manage your coin and token on
    the blockchain.`,
      it:
        `Noku Account ti garantisce l'accesso alla
    Noku 
    Platform, uno strumento innovativo fatto di interfacce, servizi
    e prodotti, progettati per gestire le tue valute e i tuoi token su
    blockchain.`
    },
    hasAppInfo: false,
    showLogout: true,
    hasOfflineCheck: true,
    hasCustomBackground: false,
    swapVisibility: false,
    cryptoHeroesVisibility: false,
    cryptoHeroesUrl: `${process.env.REACT_APP_CRYPTO_HEROES_HOST}/app`,
    marketplaceVisibility: false,
    marketplaceUrl: process.env.REACT_APP_MARKETPLACE_HOST
  };
  switch (customizationId) {
    case 'noku':
      result = {
        ...result,
        brandName: 'Noku',
        nokuChainVisibility: true,
        tokenraiseVisibility: true,
        tokenraiseUrl: `${process.env.REACT_APP_TOKENRAISE_HOST}/app/customico`,
        customtokenVisibility: true,
        customtokenUrl: `${process.env.REACT_APP_TOKENRAISE_HOST}/app/customtoken`,
        pskVisibility: false,
        walletVisibility: true,
        walletUrl: `${process.env.REACT_APP_WALLET_HOST}/app`,
        hasCustomBackground: true,
        cryptoHeroesVisibility: true,
        swapVisibility: true,
        marketplaceVisibility: true,
      };
      break;
    case 'eidoo':
      result = {
        ...result,
        brandName: 'EidooPRO',
      };
      break;
    case 'axioma':
      result = {
        ...result,
        brandName: 'Axioma',
        showLoginImage: false,
        walletVisibility: true,
        walletUrl: `${process.env.REACT_APP_WALLET_HOST}/app`,
      };
      break;
    case 'coinshare':
      result = {
        ...result,
        brandName: 'Coinshare',
        showLoginImage: true,
        walletVisibility: false,
        telegramLogin: false,
        isKycEnabled: false,
        hasUsername: true,
        has2FA: true,
        hasDefaultEthereumAddress: false,
        hasSettings: true,
        accountsDescription:
        {
          en: "A Coinshare Account allows you to get access to the Coinshare Platform.",
          it: "Un Account Coinshare ti garantisce l'accesso alla piattaforma Coinshare."
        },
        hasAppInfo: false,
        showLogout: true,
        hasOfflineCheck: true,
      };
      break;
    case 'luxochain':
      result = {
        ...result,
        brandName: 'Luxochain',
        showLoginImage: true,
        telegramLogin: false,
        isKycEnabled: false,
        hasUsername: true,
        has2FA: false,
        hasDefaultEthereumAddress: false,
        hasSettings: true,
        accountsDescription:
        {
          en: "A Luxochain Account allows you to get access to the Luxochain Platform.",
          it: "Un Account Luxochain ti garantisce l'accesso alla piattaforma Luxochain."
        },
        hasAppInfo: false,
        showLogout: true,
        hasOfflineCheck: true,
        customtokenVisibility: true,
        customtokenUrl: `${process.env.REACT_APP_TOKENRAISE_HOST}/app/customtoken`,
        walletVisibility: false,
        walletUrl: `${process.env.REACT_APP_WALLET_HOST}/app`
      };
      break;
    case 'legallab':
      result = {
        ...result,
        brandName: "LegalLab",
        customtokenVisibility: true,
        customtokenUrl: `${process.env.REACT_APP_TOKENRAISE_HOST}/app/customtoken`,
      };
      break;
    case 'nokuchain':
      result = {
        ...result,
        brandName: "Noku",
        customtokenVisibility: true,
        customtokenUrl: `${process.env.REACT_APP_TOKENRAISE_HOST}/app/customtoken`,
        hasCustomBackground: true,
      };
      break;
    default:
      break;
  }

  return result;
}

export default getCustomizationVariables;