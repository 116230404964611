import {createStore, compose, applyMiddleware, combineReducers} from "redux";
import rootReducer from './reducer';
// import { loadState, saveState } from "./nokuStorage";
import thunk from 'redux-thunk';
import {reducer as notificationsReducer} from 'reapop';

var composeEnhancers;

if(!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
  composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
}
else {
  composeEnhancers = compose;
}

const persistedState = undefined;// loadState();
// const store = createStore(rootReducer, persistedState);


const createStoreWithMiddleware = composeEnhancers(
  applyMiddleware(thunk)
)(createStore);
const store = createStoreWithMiddleware(combineReducers({
  // reducer must be mounted as `notifications` !
  notifications: notificationsReducer(),
  base: (rootReducer),
  
  // your reducers here
}, ), persistedState);


// store.subscribe(() => {
//   saveState(store.getState());
// });
export default store;