export const LOGIN = "LOGIN_TO_ACCOUNT";
export const SIGNUP = "SIGNUP_TO_ACCOUNT";
export const GET_AVATAR = "GET_AVATAR";
export const OPEN_CLOSE_SIDEBAR = "OPEN_SIDEBAR";
export const UPLOAD_FILE = "UPLOAD_FILE";
export const RESET = "RESET";
export const UPDATE_KYC = "UPDATE_KYC";
export const SET_CUSTOMIZATION_ID = "SET_CUSTOMIZATION_ID";
export const SET_MOBILE = "SET_MOBILE";
export const SET_LANGUAGES = 'SET_LANGUAGES';
